import { inject }                from '@angular/core';
import { Router } from '@angular/router';
import { UserQuery }             from '../store/user/user.query';

export const authGuard = () => {
  const userQuery = inject(UserQuery);
  const router = inject(Router);
  if (userQuery.isLoggedIn) {
    return true;
  }

  return router.parseUrl('/azonositas');
}
